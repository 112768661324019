import { faDice } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { useEffect, useState } from "react"
import { Badge, Button } from "react-bootstrap"

type Player = 1 | 2

class Result {

    dice1: boolean
    dice2: boolean
    dice3: boolean
    dice4: boolean

    constructor(dice1: boolean, dice2: boolean, dice3: boolean, dice4: boolean) {
        this.dice1 = dice1;
        this.dice2 = dice2;
        this.dice3 = dice3;
        this.dice4 = dice4;
    }

    toNumber(): number {
        return (+this.dice1) + (+this.dice2) + (+this.dice3) + (+this.dice4)
    }
}



export const UrDiceRoller: React.FC = () => {
    const [rollId, setRollId] = useState<number>(0)

    const [result, setResult] = useState<Result | undefined>(undefined)

    const [rolling, setRolling] = useState<boolean>(false)

    console.log(rollId)

    const [activePlayer, setActivePlayer] = useState<1 | 2>(1)
    const players: Player[] = [1, 2]

    const roll = (player: Player) => {
        setRollId(rollId => rollId ? rollId : 1)
        setActivePlayer(player)
        setRolling(true)
    }

    const randomResult = () => {
        const randomBytes = new Uint8Array(4);
        crypto.getRandomValues(randomBytes);
        return new Result(
            Boolean(randomBytes[0] % 2),
            Boolean(randomBytes[1] % 2),
            Boolean(randomBytes[2] % 2),
            Boolean(randomBytes[3] % 2)
        )
    }

    useEffect(() => {
        if (rolling) {
            const timeout = setTimeout(() => setRolling(false), 100)
            return () => {
                if (timeout) clearTimeout(timeout)
            }
        } else {
            setRollId(rollId => rollId ? rollId + 1 : rollId)
        }
    }, [rolling])

    useEffect(() => {
        if (rollId) {
            console.log(rollId)
            setResult(
                randomResult()
            )
        }
    }, [rollId])

    return <div className="container text-center py-3">
        <h1>The Royal Game of Ur Dice</h1>
        <hr className="my-3" />
        {result ? <div className={classNames({ "opacity-50": rolling })}>
            <div className="d-flex justify-content-center fs-3">
                <Badge bg={result.dice1 ? "primary" : "secondary"} className="mx-3" style={{ "width": "2.5em" }}>{result.dice1 ? "●" : " "}</Badge>
                <Badge bg={result.dice2 ? "primary" : "secondary"} className="mx-3" style={{ "width": "2.5em" }}>{result.dice2 ? "●" : " "}</Badge>
                <Badge bg={result.dice3 ? "primary" : "secondary"} className="mx-3" style={{ "width": "2.5em" }}>{result.dice3 ? "●" : " "}</Badge>
                <Badge bg={result.dice4 ? "primary" : "secondary"} className="mx-3" style={{ "width": "2.5em" }}>{result.dice4 ? "●" : " "}</Badge>
            </div>
            <div className="fs-1 mt-3">
                <div>{result.toNumber()}</div>
                <div className="fs-4 text-muted">(rolled by player {activePlayer})</div>
            </div>
        </div> : <p>Nothing has been rolled yet!</p>}
        <hr />
        <div className="d-flex justify-content-center">
            {players.map(player => {
                return <div key={player} className="mx-3" style={{ width: "10em" }}>
                    <h3 className="m-0 mt-3">Player {player}</h3>
                    {(rolling && player === activePlayer) ?
                        <Button size="lg" onClick={() => roll(player)} className="mt-3" disabled><FontAwesomeIcon icon={faDice} spin /> Rolling...</Button> :
                        <Button size="lg" onClick={() => roll(player)} className="mt-3" disabled={rolling}><FontAwesomeIcon icon={faDice} /> Roll!</Button>
                    }
                </div>
            })}
        </div>
        <hr />
        <p className="mt-3">
            <a href="https://www.youtube.com/watch?v=WZskjLq040I" target="_blank" rel="noreferrer">Here's</a> an excellent The British Museum + Tom Scott video explaining the game.
        </p>


    </div>
}